<template>
  <div class="-ml-4">
    <div v-if="showWrongPinWarning"
      class="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center bg-black bg-opacity-75">
        <trac-incorrect-pin 
          @cancel="showWrongPinWarning = false" 
          :message="wrongPinWarning.message" 
          :blocked="wrongPinWarning.blocked" 
          @forgot-password="$router.push({name: 'ConfirmPassword', query:{ops: 'forgot_wallet_pin'}})"
        />
    </div>

    <trac-modal v-if="shouldShowWalletNotFoundModal" :cancel="true" @close="shouldShowWalletNotFoundModal = false">
      <div class="md:w-2/4 text-center lg:w-96 mx-auto justify-center py-8 rounded-lg px-12 gap-y-4 bg-white">
        <h2 class="text-base mb-1 font-medium">Unable to display wallet record</h2>
        <p class="text-sm mb-4">Please contact Traction</p>
        <trac-button @button-clicked="$router.push({name: 'Support'})">Contact Support</trac-button>
      </div>
    </trac-modal>

    <trac-modal v-if="showPNDModal" :cancel="true" @close="closeAndReset()">
      <div class="md:w-2/4 text-center lg:w-96 mx-auto justify-center py-8 rounded-lg px-12 gap-y-4 bg-white">
        <h2 class="text-base mb-1 font-medium">Account PND Activated</h2>
        <p class="text-sm mb-4">Please Contact Traction</p>
        <trac-button @button-clicked="$router.push({name: 'Support'})">Contact Support</trac-button>
      </div>
    </trac-modal>

    <div v-if="succssfulOtp" class="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center bg-black bg-opacity-75">
      <div class="md:w-2/4 lg:w-96 mx-auto justify-center py-8 rounded-lg px-12 gap-y-4 bg-white">
        <div class="flex flex-col gap-y-3 justify-center items-center">
          <img
            src="@/assets/svg/success.svg"
            class="w-32 h-32 bg-white"
            alt=""
          />
          <p class="smallest">Confirmation Successful</p>
          <button
            @click="processAfterOtpVerification()"
            class="bg-none border-none mt-12 text-primaryBlue text-xs outline-none font-semibold"
          >
            Continue
          </button>
        </div>
      </div>
    </div>

    <div v-if="inValidOtp" class="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center bg-black bg-opacity-75">
      <div class="md:w-2/4 lg:w-96 mx-auto justify-center py-8 rounded-lg px-12 gap-y-4 bg-white">
        <div class="flex flex-col gap-y-3 justify-center items-center">
          <img
            src="@/assets/svg/failed.svg"
            class="w-16 h-16 bg-white"
            alt=""
          />
          <p class="smallest">Confirmation Failed</p>
          <button
            @click="inValidOtp = false; showOtpModal = true"
            class="bg-none border-none mt-12 text-primaryBlue text-xs outline-none font-semibold"
          >
            Okay
          </button>
        </div>
      </div>
    </div>

    <div v-if="showOtpModal" class="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center bg-black bg-opacity-75">
      <div class="flex relative flex-col items-center md:w-2/4 lg:w-96 mx-auto justify-center py-12 rounded-lg px-12 gap-y-4 bg-white">
        <img @click="showOtpModal = false" src="@/assets/svg/close.svg" class="absolute w-4 h-4 inline-block cursor-pointer" style="right:13px; top:13px">
        <h2 class="text-lg text-center font-semibold">Enter OTP to Save or Transfer to New Beneficiary</h2>
        <p class="text-center text-xs">
          Please enter the 4-digit code sent to your phone number
        </p>
        <trac-otp-input @otp-filled="assignOtp" class="my-3" />
        <trac-button :disabled="!otpIsValid" @button-clicked="checkOtp()" class="uppercase w-full">Confirm</trac-button>
        <button @click="createOtp(actionType)" class="uppercase smallest text-blue-800 font-semibold my-2">Resend OTP</button>
      </div>
    </div>

    <div v-if="showUnsanctionedDeviceModal"
      class="fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center bg-black bg-opacity-75">
      <div class="rounded-md bg-white gap-y-5 p-12 w-4/12 flex flex-col content-center text-center">
        <div class="text-center"><img class="w-12 h-12 inline-block" src="@/assets/banned.svg" alt=""></div>
        <h3 class="font-bold">Unauthorized</h3>
        <p class="text-xs">You are not authorized to perform the selected action</p>
        <div></div>
        <trac-button class="uppercase" @button-clicked="$router.push({name: 'Dashboard'})">Back to Home</trac-button>
      </div>
    </div>
    <trac-loading v-if="isLoadingBeneficiaries" />
    <main class="mx-auto w-11/12" @click="clickOutside" v-else>
      <trac-loading v-if="isLoading" />
      <trac-back-button>Wallet</trac-back-button>
      <trac-modal v-if="showPasswordModal" @close="closeAndReset()">
        <h3 class="my-5 font-bold text-center text-primaryBlue">Session Expired</h3>
        <p class="text-xs text-center">
          Please enter your Traction password to resume your progress
        </p>
        <trac-input
          v-model.trim="payload.password"
          type="password"
          placeholder="Password"
          specified="password"
          class="mt-6 w-full md:w-128"
          tag="div"
        />
        <div class="text-center mt-5">
          <trac-button @button-clicked="reVerify()">Resume</trac-button>
        </div>
      </trac-modal>
      <trac-modal v-if="benefitiaryModalCtrl" @close="benefitiaryModalCtrl = false" :cancel="reset()">
        <trac-beneficiary-details @transfer-to-benficiary="currentBeneficiarySelectedIndex = 2"
          v-if="currentBeneficiarySelectedIndex === 1"
          :selectedBeneficiary="currentlySlectedBeneficiary"></trac-beneficiary-details>
        <trac-beneficiary-transfer :selectedBeneficiary="currentlySlectedBeneficiary" :walletDetails="walletDetails"
          v-if="currentBeneficiarySelectedIndex === 2"
          @transferDetailsEmitter="setupTransferDetails"></trac-beneficiary-transfer>
        <!-- :selectedNetwork="selectedNetwork" -->
        <!-- :transactionData="transactionData" -->
        <trac-transaction-pin :walletData="walletDetails" @add-transaction-pin="addtransactionPin"
          v-if="currentBeneficiarySelectedIndex === 3"></trac-transaction-pin>
        <div class="flex flex-col justify-center items-center p-6 gap-6" v-if="0">
          <img src="./../../../assets/svg/wallet/success_check.svg" alt="" />
          <p class="">You have succesfully edited beneficiary account.</p>
        </div>
      </trac-modal>

      <trac-modal
        @close="showSuccessModal = false"
        size="max-w-4xl"
        v-if="showSuccessModal">
        <div class="my-5 flex flex-col space-y-5 justify-center items-center">
          <img src="@/assets/svg/handshake.svg" alt="" />
          <p class="text-lg font-medium">Transfer Processed</p>
        </div>

        <div class="lg:w-1/2 mx-auto">
          <div class="grid grid-cols-3 gap-4">
            <div class="flex justify-center items-center">
              <div class="rounded-full h-16 w-16 bg-accentLight flex justify-center items-center">
                <div class="text-accentDark text-2xl font-bold">{{ transactionSummary.initials }}</div>
              </div>
            </div>
            <div class="flex flex-col justify-center items-start">
              <p class="text-xs font-bold">{{ transactionSummary.accountName }}</p>
              <p class="text-xs">{{ transactionSummary.accountNumber }}</p>
              <p class="text-xs">{{ transactionSummary.bankName }}</p>
            </div>
            <div class="flex justify-end items-center">
              <p class="text-xs font-black">N{{ transactionSummary.amount }}</p>
            </div>
          </div>
          <hr class="my-4">
          <div class="text-center text-gray-600 my-3">
            {{ transactionSummary.date }} | {{ transactionSummary.time }}
          </div>
          <div class="text-center text-gray-600 my-3">
            Transaction ID - {{ transactionSummary.reference }}
          </div>
          <!-- create flex div that center aligns -->
          <div class="flex justify-center items-center mt-10">
            <trac-button class="text-white uppercase bg-accentDark border-accentDark" @button-clicked="$router.push({name: 'MainWallet'})">Back to Wallet</trac-button>
          </div>
        </div>
      </trac-modal>

      <trac-modal v-if="deleteModal" @close="deleteModal = false">
        <div class="mx-10 my-8 text-center">
          <span class="font-semibold text-sm">Are you sure you want to delete
            {{ currentlySlectedBeneficiary.accountname }}?</span>
          <div class="flex flex-col gap-2 md:gap-0 md:flex-row w-8/12 justify-between mx-auto mt-8">
            <trac-button variant="outline" @button-clicked="deleteModal = false">Cancel
            </trac-button>
            <trac-button class="text-secondaryRed border-secondaryRed" variant="outline"
              @button-clicked="deleteBeneficiary(currentlySlectedBeneficiary)">Delete
            </trac-button>
          </div>
        </div>
      </trac-modal>

      <trac-modal class="" v-if="addBeneficiaryModalCtrl" @close="reset()">
        <div class="p-10" v-if="!previewBankDetails">
          <h2 class="mt-6 font-bold">Add Beneficiary Details</h2>
          <trac-dropdown-exteneded :placeholderText="'Search Bank'" :shouldFilter="true" @filter-list="setSearchedBank" :neededProperty="'bankname'" :options="filteredBanks" :selector="'Select Bank'" class="mt-6"
            @optionSelected="selectBenficiaryBank($event)"></trac-dropdown-exteneded>
          <trac-input v-model="newBeneficiary.accountNumber" class="mt-6" type="number" placeholder="Account Number"></trac-input>
          <div class="flex justify-end mt-8">
            <trac-button :disabled="!this.newBeneficiary.bankcode && !this.newBeneficiary.accountNumber" @button-clicked="confirmBeneficiary">Proceed</trac-button>
          </div>
        </div>
        <div class="p-10" v-else>
          <h2 class="mt-6 font-bold">Review Details</h2>
          <!-- <trac-dropdown-exteneded :neededProperty="'bankname'" :options="banks" :selector="'Select Bank'" class="mt-6"
            @optionSelected="selectBenficiaryBank($event)"></trac-dropdown-exteneded>
          <trac-input v-model="newBeneficiary.accountNumber" class="mt-6" type="number" placeholder="Account Number"></trac-input> -->
          <!-- create a two column layout -->
          <div class="flex justify-between mt-6">
            <div class="w-1/2">
              <p class="text-xs text-gray-600">Account Name</p>
              <p class="font-bold">{{ newBeneficiary.accountName }}</p>
            </div>
            <div class="w-1/2">
              <p class="text-xs text-gray-600">Account Number</p>
              <p class="font-bold">{{ newBeneficiary.accountNumber }}</p>
            </div>
          </div>
          <div class="flex justify-end gap-x-3 mt-8">
            <trac-button @button-clicked="checkOtpSettingFirst('add-transfer')">TRANSFER NOW</trac-button>
            <trac-button @button-clicked="checkOtpSettingFirst('add-beneficiary')">SAVE BENEFICIARY</trac-button>
          </div>
        </div>
      </trac-modal>

      <div class="">
        <!-- <trac-back-button>Back</trac-back-button> -->
        <div class="flex justify-between items-center my-6">
          <h2 class="">All Beneficiaries</h2>
          <trac-button class="" @button-clicked="checkWalletExistsBeforeAddingBeneficiary">Add Beneficiary</trac-button>
        </div>
        <div class="rounded-md shadow-md p-4">
          <div class="my-4">
            <trac-input placeholder="Search account name or number" class="md:w-2/5"
              v-model="searchedBeneficiary"></trac-input>
          </div>
          <div class="md:hidden">
            <div v-for="(beneficiary, i) in allFilteredBeneficiaries" :key="i" class="flex py-3">
              <div @click="showBeneficiaryDetails(beneficiary)" class="
                  smallest
                  h-8
                  w-8
                  flex
                  justify-center
                  items-center
                  rounded-full
                  mr-4
                  bg-accentLight
                ">
                <div class="tiny text-accentDark cursor-pointer uppercase">
                  {{
    beneficiary.accountname
      .split(" ")
      .map((name) => name.substring(0, 1))
      .join("")
}}
                </div>
              </div>
              <div class="text-xs text-gray-600 flex-1" @click="showBeneficiaryDetails(beneficiary)">
                <p class="font-bold mt-1">{{ beneficiary.accountname }}</p>
                <p class="">
                  {{ beneficiary.accountnumber }} | {{ beneficiary.bankname }}
                </p>
              </div>
              <div class="flex relative">
                <img @click="openBeneficiaryOptions(beneficiary, i)" src="./../../../assets/svg/wallet/more_vert.svg"
                  alt="" class="beneficiary-options" />
                <div v-if="
  i === currentSelectedBenficiaryOptions && optionsOpened
" class="
                        absolute
                        bg-white
                        shadow-md
                        p-2
                        rounded-md
                        beneficiary-options
                      " style="right: 70%; top: 30%">
                  <p @click="deleteModal = true" class="
                          text-sm
                          p-1
                          hover:bg-veryLight
                          m-1
                          text-red-600
                          beneficiary-options
                        ">
                    Delete
                  </p>
                </div>
              </div>
            </div>
            <div v-if="allFilteredBeneficiaries.length === 0" class="flex justify-center items-center h-60 text-sm">
              No beneficiaries added yet.
            </div>
          </div>

          <div class="overflow-x-visible hidden md:block" v-if="allBeneficiaries">
            <table class="w-full">
              <caption class="sr-only">Beneficiaries</caption>
              <thead>
                <tr class="bg-accentLight">
                  <th class="whitespace-no-wrap p-4 text-xs text-left">Name</th>
                  <th class="whitespace-no-wrap p-4 text-xs text-left">
                    Account Number
                  </th>
                  <th class="whitespace-no-wrap p-4 text-xs text-left">
                    Bank Name
                  </th>
                  <!-- <th class="whitespace-no-wrap p-4 text-xs text-left">
                    Amount Sent
                  </th> -->
                  <th class="whitespace-no-wrap py-4 px-8 text-xs text-left">
                    Action
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="allBeneficiaries.length > 0">
                <tr v-for="(beneficiary, i) in allFilteredBeneficiaries" :key="i"
                  class="hover:bg-transparentBackground cursor-pointer">
                  <td @click="showBeneficiaryDetails(beneficiary)" class="whitespace-no-wrap p-4 text-xs">
                    {{ beneficiary.accountname }}
                  </td>
                  <td @click="showBeneficiaryDetails(beneficiary)" class="whitespace-no-wrap p-4 text-xs">
                    {{ beneficiary.accountnumber }}
                  </td>
                  <td @click="showBeneficiaryDetails(beneficiary)" class="whitespace-no-wrap p-4 text-xs">
                    {{ beneficiary.bankname }}
                  </td>
                  <!-- <td
                    @click="showBeneficiaryDetails(beneficiary)"
                    class="whitespace-no-wrap p-4 text-xs"
                  >
                    ----
                  </td> -->
                  <td class="whitespace-no-wrap p-4 text-xs">
                    <trac-button class="ml-4" variant="icon" @button-clicked="transferToBeneficiary(beneficiary)">
                      Send Money
                    </trac-button>
                  </td>
                  <td class="relative">
                    <img @click="openBeneficiaryOptions(beneficiary, i)"
                      src="./../../../assets/svg/wallet/more_vert.svg" alt="" class="beneficiary-options" />
                    <div v-if="
  i === currentSelectedBenficiaryOptions && optionsOpened
" class="
                        absolute
                        bg-white
                        shadow-md
                        p-2
                        rounded-md
                        beneficiary-options
                      " style="left: 70%; top: 30%">
                      <!-- <p
                        @click="editBeneficiary(beneficiary)"
                        class="
                          text-sm
                          p-1
                          hover:bg-veryLight
                          m-1
                          text-gray-800
                          beneficiary-options
                        "
                      >
                        Edit
                      </p>
                      <hr /> -->
                      <p @click="deleteModal = true" class="
                          text-sm
                          p-1
                          hover:bg-veryLight
                          m-1
                          text-red-600
                          beneficiary-options
                        ">
                        Delete
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="5" class="">
                    <div class="flex justify-center items-center h-60 text-sm">
                      No beneficiaries added yet.
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="flex justify-between pt-4 pb-10 items-center w-full">
          <trac-button @button-clicked="adjustPage('prev')" :disabled="!showPrev" :class="{'opacity-25': !showPrev}">Prev</trac-button>
          <trac-button @button-clicked="adjustPage('next')">Next</trac-button>         
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import BeneficiaryDetailsVue from "./beneficiaries-components/BeneficiaryDetails.vue";
import MakeTransferVue from "./beneficiaries-components/MakeTransfer.vue";
import TransactionPin from "./../wallet-components/airtime-transaction/TransactionPin.vue";

import { eventBus } from "./../../../eventBus";
import { GET_USER_BUSINESS_ID, GET_USER_DATA } from "../../../browser-db-config/localStorage";
import moment from 'moment';
import WalletToken from '../../security/WalletToken';
import WhiteListingMixin from '../../../security-module/WhiteListingMixin';

export default {
  mixins: [WalletToken, WhiteListingMixin],
  components: {
    "trac-beneficiary-details": BeneficiaryDetailsVue,
    "trac-beneficiary-transfer": MakeTransferVue,
    "trac-transaction-pin": TransactionPin,
  },
  data() {
    return {
      page: 0,
      succssfulOtp: false,
      previewBankDetails: false,
      showSuccessModal: false,
      isLoading: false,
      isLoadingBeneficiaries: false,
      benefitiaryModalCtrl: false,
      deleteModal: false,
      addBeneficiaryModalCtrl: false,
      shouldShowWalletNotFoundModal: false,
      transactionSummary: {
        initials: '',
        accountName: '',
        accountNumber: '',
        bankName: '',
        amount: '',
        date: '',
        time: '',
        reference: ''
      },
      successfulTransaction: false,
      currentSelectedBenficiaryOptions: null,
      currentBeneficiarySelectedIndex: null,
      currentlySlectedBeneficiary: null,
      optionsOpened: false,
      allBeneficiaries: null,
      transferDetail: null,
      walletDetails: null,
      searchedBeneficiary: "",
      searchedBank: "",
      newBeneficiary: {
        accountNumber: "",
        bankSelected: "",
        bankcode: "",
        accountName: ""
      },
      banks: [],
      user: GET_USER_DATA(),
      actionType: ''
    };
  },
  async created() {
    if (!this.hasLocalWalletToken()) {
      this.goToWalletAuth()
    } else {
      this.isLoadingBeneficiaries = true;
      await this.fetchWalletDetails();
      await this.fetchAllBeneficiariesBanks();
      await this.fetchAllBeneficiaries();
      this.isLoadingBeneficiaries = false;
    }
    // this.checkIncorrectPin({
    //   data: null,
    //   message: "Incorrect PIN entered. You have 3 more attempts remaining before you will be denied access to your wallet. If you have forgotten your PIN, click Forgot PIN",
    //   status: false,
    //   code: "WAL001"
    // })
  },
  watch: {
    walletDetails(newValue, oldValue) {
      if(!newValue) {
        this.shouldShowWalletNotFoundModal = true
      }
      else {
        this.shouldShowWalletNotFoundModal = false
      }
    }
  },
  computed: {
    showPrev() {
      return this.page > 0
    },
    filteredBanks () {
      if (this.searchedBank || this.searchedBank.length > 0) {
        return this.banks.filter((bank) =>
          bank.bankname.toLowerCase().includes(this.searchedBank.toLowerCase())
        );
      } else {
        return this.banks
      }
    },
    allFilteredBeneficiaries() {
      return this.allBeneficiaries.filter(
        (beneficiary) =>
          beneficiary.accountname.toLowerCase().includes(this.searchedBeneficiary.toLowerCase()) || beneficiary.accountnumber.includes(this.searchedBeneficiary)
      );
    },
  },
  methods: {
    closeAndReset() {
      console.log('closeAndReset')
      this.showPNDModal = false
      this.showPasswordModal = false
      this.reset()
    },
    checkOtpSettingFirst(type) {
      console.log('checkOtpSettingFirst() called. Otp is currently at ', this.walletDetails.hasOtpEnabled)
      this.actionType = type
      if(this.walletDetails.hasOtpEnabled) {
        this.createOtp(type)
      } else {
        this.processAfterOtpVerification()
        // this.currentBeneficiarySelectedIndex = 1
      }
    },
    async processAfterOtpVerification() {
      if (this.actionType === 'add-transfer') {
        this.assignNewBeneficiaryToCurrentlySelectBeneficiary()
      }
      if (this.actionType === 'add-beneficiary'){
        await this.sendBeneficiaryDetails()
        this.succssfulOtp = false
      }
    },
    createOtp(type = null) {
      this.actionType = type
      this.isLoading = true;
      // debugger
      const payload = {
          userId: this.user._id,
          type: type,
          channels: ["email", "sms"] // optional, defaults to ["email", "sms"]
      }
      // debugger
      this.$store.dispatch('REQUEST_OTP', payload).then((res) => {
        if(res.status) {
          this.showOtpModal = true; 
          this.addBeneficiaryModalCtrl = false
          this.isLoading = false;
          eventBus.$emit('trac-alert', {
            message: res.message
          })
        }
      }).catch((err) => {
        console.error('otp err', err)
        this.isLoading = false;
      })
    },
    assignOtp(data) {
      this.otp = data
    },
    checkOtp() {
      const payload = {
          userId: this.user._id,
          code: this.otp,
          type: this.actionType, // any of: "add-transfer", "add-beneficiary" (must be same with what was sent earlier)
      }
      this.isLoading = true
      this.$store.dispatch('CHECK_OTP', payload).then((res) => {
        if (res.status) {
          this.succssfulOtp = true 
          this.showOtpModal = false
          this.isLoading = false
        } else {
          this.inValidOtp = true
          this.succssfulOtp = false 
          this.showOtpModal = false
          this.isLoading = false
        }
      }).catch((err) => {
        console.error('otp err', err)
      })
      // if (this.otp === '1111') {
      //   this.succssfulOtp = true 
      //   this.showOtpModal = false
      // } else {
      //   this.inValidOtp = true
      //   this.succssfulOtp = false 
      //   this.showOtpModal = false
      // }
    },
    assignNewBeneficiaryToCurrentlySelectBeneficiary() {
      this.currentlySlectedBeneficiary = {
        accountname: this.newBeneficiary.accountName,
        accountnumber: this.newBeneficiary.accountNumber,
        bankcode: this.newBeneficiary.bankcode,
        bankname: this.newBeneficiary.bankSelected
      }
      this.benefitiaryModalCtrl = true
      this.showOtpModal = false
      this.succssfulOtp = false
      this.currentBeneficiarySelectedIndex = 2
    },
    reset() {
      this.newBeneficiary = {
        accountNumber: "",
        bankSelected: "",
        bankcode: "",
        accountName: ""
      };
      this.addBeneficiaryModalCtrl = false
      this.previewBankDetails = false;
      this.searchedBank = ""
    },
    async confirmBeneficiary() {
      if (this.newBeneficiary.bankcode === ""){
        eventBus.$emit("trac-alert", {
          message:
           "Please select a bank",
        });
        return 
      }
      if (this.newBeneficiary.accountNumber.length !== 10){
        eventBus.$emit("trac-alert", {
          message:
           "Account number must be 10 characters",
        });
        return 
        
      }
      this.isLoading = true;
      const bankDetailsRes = await this.resolveBankAccount().catch((err) => {
        console.error('resolve beneficiary error:',err);
        eventBus.$emit("trac-alert", {
          message: err.message || "Error: Couldn't resolve account details.",
        });
      });
      if (bankDetailsRes) {
        this.previewBankDetails = bankDetailsRes.status || false;
        this.newBeneficiary.bankcode = bankDetailsRes.data.bankcode
        this.newBeneficiary.accountName = bankDetailsRes.data.accountname
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
    setSearchedBank(searchedBank) {
      console.log('searchedBank', searchedBank)
      this.searchedBank = searchedBank
    },
    openBeneficiaryOptions(benficiary, i) {
      this.currentlySlectedBeneficiary = benficiary;
      this.currentSelectedBenficiaryOptions = i;
      this.optionsOpened = true;
    },
    showBeneficiaryDetails(beneficiary, i) {
      this.benefitiaryModalCtrl = true;
      this.currentlySlectedBeneficiary = beneficiary;
      this.currentBeneficiarySelectedIndex = 1;
    },
    transferToBeneficiary(beneficiary) {
      if (!this.walletDetails) {
        eventBus.$emit("trac-alert", {
          message: "Wallet details hasn't loaded yet. Please refresh.",
        });

        return;
      }

      this.benefitiaryModalCtrl = true;
      this.currentlySlectedBeneficiary = beneficiary;
      this.currentBeneficiarySelectedIndex = 2;
    },
    editBeneficiary(beneficiary) {
      this.addBeneficiaryModalCtrl = true;
    },
    clickOutside(e) {
      if (!e.target.classList.contains("beneficiary-options")) {
        this.optionsOpened = false;
      }
    },
    setupTransferDetails(transferDetail) {
      this.currentBeneficiarySelectedIndex = 3;
      this.transferDetail = transferDetail;
      this.lockOutAfterInActivity();
    },
    reVerify() {
      this.verifyThenProcess().then(() => {
        this.addtransactionPin(this.temporaryPin)
      })
    },
    async addtransactionPin(transactionPinData) {
      this.isLoading = true;
      // this.transactionPinData = transactionPinData;
      // console.log(this.transactionPinData);
      const res = await this.$store.dispatch("VERIFY_USER_PIN", {
        ...transactionPinData,
      });

      if (res.status) {
        const [res, error] = await this.verifyWalletToken()
        // debugger
        if (!error) {
          res.status ? this.makeTransferToBeneficiary(this.transferDetail) : 
            this.benefitiaryModalCtrl = false; 
            this.holdTemporaryPayloadForRetry(this.transferDetail, transactionPinData)
          this.isLoading = false;
        }
        // if (this.transactionData.transactionType === "airtime") {
        //   await this.makeAirtimeRequest();
        // } else {
        //   this.makeDataRequest();
        // }
      } else {
        this.isLoading = false;
        this.checkIncorrectPin(res)
        this.isSanctioned(res)
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Invalid pin",
        });
        if (this.isPNDActivated(res)) {
          this.showPasswordModal = false
        }
      }
    },
    async generateDeviceID() {
      return this.$fpjs.getVisitorData({
        extendedResult: true
      }).then((fingerprint) => {
        // console.log("Generated Fingerprint: ", fingerprint.visitorId);
        return fingerprint.visitorId
      })
    },
    async makeTransferToBeneficiary(transferDetails) {
      // Check if it's for specific user
      this.makeTransaction('', transferDetails)
    },
    async makeTransaction(deviceId, transferDetails) {
      this.isLoading = true;
      const beneficiaryTransferRes = await this.$store.dispatch(
        "TRANSFER_TO_BENEFICIARY",
        {
          transferDetails,
          wallet: this.walletDetails,
          deviceID: deviceId,
        }
      );

      if (beneficiaryTransferRes.status) {
        this.transactionSummary = {
          initials: beneficiaryTransferRes.data.craccountname
            .split(" ")
            .map((name) => name.substring(0, 1))
            .join(""),
          accountName: beneficiaryTransferRes.data.craccountname,
          accountNumber: beneficiaryTransferRes.data.craccount,
          bankName: beneficiaryTransferRes.data.bankname,
          amount: beneficiaryTransferRes.data.amount,
          date: moment(beneficiaryTransferRes.data.createdOn).format("DD MMMM yyyy"),
          time: moment(beneficiaryTransferRes.data.createdOn).format("HH:mm A"),
          reference: beneficiaryTransferRes.data.reference,
        };
        this.benefitiaryModalCtrl = false;
        this.showSuccessModal = true
        this.showPasswordModal = false
        // eventBus.$emit("trac-alert", {
        //   message:
        //     beneficiaryTransferRes.message || "Funds transferred successfully.",
        // });
      } else {
        this.benefitiaryModalCtrl = false;
        if(this.isPNDActivated(beneficiaryTransferRes)) {
          this.showPasswordModal = false
        }
        eventBus.$emit("trac-alert", {
          message:
            beneficiaryTransferRes.message || "Error: Couldn't make transfer.",
        });
      }
      this.isLoading = false;
    },
    async fetchWalletDetails() {
      this.isLoading = true;
      const res = await this.$store.dispatch("FETCH_WALLET_DETAILS");
      if(res.status) {
        this.walletDetails = res.data || null
      } else {
        this.shouldShowWalletNotFoundModal = true
      }
      this.isLoading = false;
    },
    selectBenficiaryBank(bankDetails) {
      console.log('selectBenficiaryBank', bankDetails)
      this.newBeneficiary.bankcode = bankDetails.bankcode;
      this.newBeneficiary.bankSelected = bankDetails.bankname;
    },
    async createBeneficiary(options = {openAsTransfer: false}) {
      const newAccount = this.newBeneficiary.accountNumber
      if (this.newBeneficiary.bankcode === ""){
        eventBus.$emit("trac-alert", {
          message:
           "Please select a bank",
        });
        return 
      }
      if (this.newBeneficiary.accountNumber.length !== 10){
        eventBus.$emit("trac-alert", {
          message:
           "Account number must be 10 characters",
        });
        return 
      }
      this.isLoading = true;
      await this.sendBeneficiaryDetails();
  
      this.isLoading = false;
      await this.fetchAllBeneficiaries();
      if (options.openAsTransfer) {
        const recentlyAddedBeneficiary = this.allBeneficiaries.find(
          (beneficiary) =>
            beneficiary.accountnumber === newAccount
        );
        console.log(recentlyAddedBeneficiary, 'recentlyAddedBeneficiary')
        this.showBeneficiaryDetails(recentlyAddedBeneficiary)
      }
      this.reset();
    
    },
    async sendBeneficiaryDetails() {
      this.isLoading = true
      const payload = {
        accountnumber: this.newBeneficiary.accountNumber,
        accountname: this.newBeneficiary.accountName,
        kyc: "",
        bvn: "",
        bankcode: this.newBeneficiary.bankcode,
        isdefault: false,
        userid: GET_USER_BUSINESS_ID(),
      };

      const createdBeneficiaryRes = await this.$store.dispatch(
        "CREATE_BENEFICIARIES",
        payload
      );

      if (createdBeneficiaryRes.status) {
        this.addBeneficiaryModalCtrl = false;
        this.reset()

        eventBus.$emit("trac-alert", {
          message: "Beneficiary created successfully.",
        });
        this.fetchAllBeneficiaries();
      } else {
        // Alert error.
        eventBus.$emit("trac-alert", {
          message:
            createdBeneficiaryRes.message ||
            "Error: Couldn't create beneficiary.",
        });
      }
      this.isLoading = false
    },
    async deleteBeneficiary(beneficiary) {
      this.isLoading = true;
      const payload = {
        benficiaryID: beneficiary.beneficiaryGuid,
        userid: GET_USER_BUSINESS_ID(),
      };

      const deleteBeneficiaryRes = await this.$store.dispatch(
        "DELETE_BENEFICIARIES",
        payload
      );

      if (deleteBeneficiaryRes.status) {
        this.addBeneficiaryModalCtrl = false;
        this.optionsOpened = false;
        this.deleteModal = false;

        // Update list
        const beneficiariesRes = this.$store.getters["GET_ALL_BENEFICIARIES"];
        this.allBeneficiaries = beneficiariesRes.data;
        this.currentSelectedBenficiaryOptions = null;

        eventBus.$emit("trac-alert", {
          message: "Beneficiary deleted successfully.",
        });
      } else {
        // Alert error.
        eventBus.$emit("trac-alert", {
          message:
            deleteBeneficiaryRes.message ||
            "Error: Couldn't create beneficiary.",
        });
      }
      this.isLoading = false;
    },
    async resolveBankAccount() {
      const payload = {
        accountnumber: this.newBeneficiary.accountNumber,
        bankcode: this.newBeneficiary.bankcode,
      };

      const resolvedBankData = await this.$store.dispatch(
        "FETCH_BENFICIARY_BANK_DETAILS",
        payload
      );

      if (resolvedBankData.status) {
        return resolvedBankData;
      } else {
        // alert(res.message);
        eventBus.$emit("trac-alert", { message: resolvedBankData.message });
      }
    },
    async checkWalletExistsBeforeAddingBeneficiary() {
      if(!this.walletDetails) {
        await this.fetchWalletDetails()
        if(this.walletDetails){
          this.openAddBeneficiaryModal()
        }
      } else {
        this.openAddBeneficiaryModal()
      }
    },
    async openAddBeneficiaryModal() {
      // this.isLoading = true;
      this.addBeneficiaryModalCtrl = true;
      // const beneficiariesBanksRes = await this.fetchAllBeneficiariesBanks();
      // this.isLoading = false;
    },
    adjustPage(direction = 'next') {
      if(direction === 'next') {
        this.page += 1
        this.fetchAllBeneficiaries()
      }
      if(direction === 'prev') {
        this.page = this.page === 0 ? this.page : this.page - 1
        this.fetchAllBeneficiaries()
      }
    },
    async fetchAllBeneficiaries() {
      this.isLoading = true
      await this.$store.dispatch("FETCH_ALL_BENEFICIARIES", {
        page: this.page 
      });
      const beneficiariesRes = this.$store.getters["GET_ALL_BENEFICIARIES"];

      if (beneficiariesRes.status) {
        const list = beneficiariesRes.data.map((beneficiary) => {
          const bankName = this.banks.find(
            (bank) => bank.bankcode === beneficiary.bankcode
          );

          return { ...beneficiary, bankname: (bankName || {}).bankname };
        });
        this.allBeneficiaries = list.sort((a, b) =>
          a.accountname > b.accountname ? 1 : -1
        );
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    async fetchAllBeneficiariesBanks() {
      const res = await this.$store.dispatch("FETCH_ALL_BENEFICIARIES_BANKS");
      const beneficiariesBanksRes =
        this.$store.getters["GET_ALL_BENFICIARY_BANKS"];

        console.log(beneficiariesBanksRes, 'finish her')

      if (beneficiariesBanksRes.status) {
        this.banks = (beneficiariesBanksRes.data || []).sort((a, b) =>
          a.bankname > b.bankname ? 1 : -1
        );
      } else {
        this.addBeneficiaryModalCtrl = false;
        // Alert error.
        eventBus.$emit("trac-alert", {
          message:
            beneficiariesBanksRes.message || "Error: Couldn't load banks",
        });
      }
      // return beneficiariesBanksRes;
    },
  },
};
</script>

<style>

</style>