export default {
    data() {
        return {
            limits : {
                airtime: {
                    min: {
                        value: 100,
                    },
                    max: {
                        value: 50000,
                    }
                },
                electricity: {
                    min: {
                        value: 1000,
                    },
                    max: {
                        value: 1000000,
                    }
                },
                wallet: {
                    min: {
                        value: 100,
                    },
                    max: {
                        value: 10000000,
                    }
                }
            }
        }
    },
    methods: {
        checkAirtimeLimits(value = 0) {
            const outcome = {
                valid: false,
                reason: ''
            }
            const belowMin = value < this.limits.airtime.min.value
            const aboveMax = value > this.limits.airtime.max.value
            if (belowMin) {
                outcome.reason = `Min allowed amount is ${this.$options.filters.formatPrice(this.limits.airtime.min.value)}`
            }
            if (aboveMax) {
                outcome.reason = `Max allowed amount is ${this.$options.filters.formatPrice(this.limits.airtime.max.value)}`
            }
            
            outcome.valid = !belowMin && !aboveMax

            return outcome
        },
        checkWalletLimits(value = 0) {
            const outcome = {
                valid: false,
                reason: ''
            }
            const belowMin = value < this.limits.wallet.min.value
            const aboveMax = value > this.limits.wallet.max.value
            if (belowMin) {
                outcome.reason = `Minimum transfer amount is ${this.$options.filters.formatPrice(this.limits.wallet.min.value)}`
            }
            if (aboveMax) {
                outcome.reason = `Maximum transfer amount is ${this.$options.filters.formatPrice(this.limits.wallet.max.value)}`
            }
            
            outcome.valid = !belowMin && !aboveMax

            return outcome
        },
        checkElectricityLimits(value) {
            const outcome = {
                valid: false,
                reason: ''
            }
            const belowMin = value < this.limits.electricity.min.value
            const aboveMax = value > this.limits.electricity.max.value
            if (belowMin) {
                outcome.reason = `Min allowed amount is ${this.$options.filters.formatPrice(this.limits.electricity.min.value)}`
            }
            if (aboveMax) {
                outcome.reason = `Max allowed amount is ${this.$options.filters.formatPrice(this.limits.electricity.max.value)}`
            }

            outcome.valid = !belowMin && !aboveMax

            return outcome
        }
    },
}